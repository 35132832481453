// Import our CSS
import styles from "../css/app.pcss";
import Alpine from "alpinejs";
import AOS from "aos";
import "aos/dist/aos.css";

// App main
const main = async () => {
	await import("lazysizes/plugins/bgset/ls.bgset");
};
window.Alpine = Alpine;

// Execute async function
main().then(() => {
	// Helpers
	var parseHTML = function (str) {
		const tmp = document.implementation.createHTMLDocument("tmp");
		tmp.body.innerHTML = str;
		return tmp.body.children;
	};

	var _si = function (selector, context = document, returnNull = false) {
		const tmp = context.querySelector(selector);
		return tmp ? tmp : returnNull ? null : _c("div");
	};
	var _s = function (selector, context = document) {
		return Array.apply(null, context.querySelectorAll(selector));
	};

	var _c = function (elm) {
		return document.createElement(elm);
	};

	var _ael = function (sel, ev, callback) {
		document.addEventListener(ev, (e) => {
			if (e.target === document) {
				return;
			}
			const that = e.target.closest(sel);
			if (that) {
				callback(that, e);
			}
		});
	};
	// END HELPERS
	const html = _si("html");
	const body = document.body;
	window.addEventListener("load", function () {
		body.style.opacity = "1";
		body.classList.add("loaded");
	});
	const the_animation = document.querySelectorAll(".animation");
	const observer = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("scroll-animation");
				} else {
					entry.target.classList.remove("scroll-animation");
				}
			});
		},
		{ threshold: 0.5 }
	);
	//
	for (let i = 0; i < the_animation.length; i++) {
		const elements = the_animation[i];

		observer.observe(elements);
	}
	window.app = {
		menu() {
			return {
				showMenu: false,
			};
		},
	};
	Alpine.start();
	AOS.init();
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
